import React from 'react';
import { Tooltip, Grid } from '@mui/material';
import { Lens } from '@mui/icons-material';
import {
    convertCamelCaseToString,
    convertTempAsPerUserSettings,
    getUserUnit,
} from './utility-functions';
import GetSvgIcon from './../util/svgImage_util';
import { getImage } from './../util/trip_util';

const textContent = {
    display: 'flex',
    lineHeight: 1,
    maxWidth: 75,
};

type getTrackerBatteryStatusProps = {
    deviceBatteryStatus: string,
    intVolt: number,
};

const batteryStatusConstant = {
    weak: {
        colorValue: '#fc0207',
        label: 'Weak',
    },
    good: {
        colorValue: '#7ed321',
        label: 'Good',
    },
    other: {
        colorValue: '#9b9b9b',
        label: 'Not Reporting',
    },
};

export const getTitle = (titleName) => {
    if (titleName && titleName.length > 20) {
        const title = (
            <span>{titleName.slice(0, 20)}&hellip;</span>
        );
        return <Tooltip title={titleName}>{title}</Tooltip>;
    }
    return titleName;
};

export const getSplittedHeader = (arg1, arg2, key) => {
    const value = (
        <div style={{ lineHeight: '18px', textAlign: arg2 ? 'center' : 'none', textTransform: 'none' }}>
            <div>{arg1}</div>
            <div>{arg2}</div>
        </div>
    );
    const style = {};
    if (arg1 || arg2) {
        const maxLength = arg1.length > arg2.length ? arg1.length : arg2.length;
        style.minWidth = `${maxLength}ch`;
    }
    return {
        [key]: value,
        reportColumnTitle: `${arg1} ${arg2}`,
        style,
    };
};

export const downloadReports = (href, value) => {
    const link = document.createElement('a');
    link.href = href;
    link.download = value;
    link.style.display = 'none';
    link.click();
};

const getInnerGrid = (xs, color, value) => (
    <Grid item xs={xs}>
        <span
            style={{ color }}
        >
            {value}
        </span>
    </Grid>
);

const getCommonTemp = data => (
    <React.Fragment>
        <Tooltip title={data.title} disableFocusListener>
            <Grid container style={{ display: 'flex', maxWidth: 75, ...data.coustomStyle }}>
                <Grid item xs={5}>
                    <GetSvgIcon type={data.type} fillcolor={data.color} />
                </Grid>
                {getInnerGrid(7, data.color, data.value)}
            </Grid>
        </Tooltip>
    </React.Fragment>
);

export const getInputStatusCell = (n, style) => {
    const coustomStyle = style || {};
    const inputs = ['input1', 'input2', 'input3', 'input4'];
    const cell = (
        <React.Fragment>
            <Grid container style={{ ...textContent, ...coustomStyle }}>
                {inputs.map(input =>
                    n[`${input}StatusLabel`] && (
                        <Grid item xs={3} key={input}>
                            <Tooltip title={`${convertCamelCaseToString(n[`${input}StatusLabel`])}`} disableFocusListener>
                                <Lens color={n[`${input}On`] ? 'primary' : 'disabled'} style={{ width: 14 }} />
                            </Tooltip>
                        </Grid>
                    ))}
            </Grid>
        </React.Fragment>
    );
    return cell;
};

const getColor = level => ((level && level.toLowerCase() === 'low') ? '#fc0207' : '#7ed321');

const getTireColor = status => ((status && status.toLowerCase() === 'default') ? '#9B9B9B' : getColor(status));

export const getOilCell = (n, style) => {
    let cell = n.oilLifeRemainingStatus || '';
    if (n.oilLifeRemainingStatus && n.oilLifeRemainingStatus.match(/low|high|full/gi)) {
        const data = {
            title: `${Math.round(n.oilLifeRemaining)}%`,
            type: 'oil-life',
            value: `${Math.round(n.oilLifeRemaining)}%`,
            color: getColor(n.oilLifeRemainingStatus),
            coustomStyle: style || {},
        };
        cell = getCommonTemp(data);
    }
    return cell;
};

export const getSeatBeltCell = (n, style) => {
    let cell = n.seatBeltStatus || '';
    if (n.seatBeltStatus && (n.seatBeltStatus.toUpperCase() === 'ON' || n.seatBeltStatus.toUpperCase() === 'OFF')) {
        const data = {
            title: `${n.seatBeltStatus.toUpperCase()}`,
            type: 'seatbelt',
            value: n.seatBeltStatus.toUpperCase(),
            color: getColor((n.seatBeltStatus.toUpperCase() === 'OFF') ? 'low' : 'high'),
            coustomStyle: style || {},
        };
        cell = getCommonTemp(data);
    }
    return cell;
};

export const getTirePressureCell = (n, style) => {
    let cell = '';
    const coustomStyle = style || {};
    if (n.tirePressureBLStatus === 'N/A' && n.tirePressureFLStatus === 'N/A') {
        cell = 'N/A';
    } else if (n.tirePressureBLStatus && n.tirePressureFLStatus) {
        const tiresF = ['tirePressureFL', 'tirePressureFR'];
        const tiresB = ['tirePressureBL', 'tirePressureBR'];
        cell = (
            <React.Fragment>
                <Grid container style={{ display: 'flex', maxWidth: 75, ...coustomStyle }}>
                    {tiresF.map(tire =>
                        (
                            <React.Fragment key={tire}>
                                {tire === 'tirePressureFL' &&
                                    getInnerGrid(3, getTireColor(n[`${tire}Status`]), Math.round(n[tire]))
                                }
                                <Grid item xs={3}>
                                    <Tooltip title={Math.round(n[tire])} disableFocusListener>
                                        <span>
                                            <GetSvgIcon style={{ width: '95%' }} type="tire-pressure" fillcolor={getTireColor(n[`${tire}Status`])} />
                                        </span>
                                    </Tooltip>
                                </Grid>
                                {tire === 'tirePressureFR' &&
                                    getInnerGrid(3, getTireColor(n[`${tire}Status`]), Math.round(n[tire]))
                                }
                            </React.Fragment>
                        ))}
                    {tiresB.map(tire =>
                        (
                            <React.Fragment key={tire}>
                                {tire === 'tirePressureBL' &&
                                    getInnerGrid(3, getTireColor(n[`${tire}Status`]), Math.round(n[tire]))
                                }
                                <Grid item xs={3} key={tire}>
                                    <Tooltip title={Math.round(n[tire])} disableFocusListener>
                                        <span>
                                            <GetSvgIcon style={{ width: '95%' }} type="tire-pressure" fillcolor={getTireColor(n[`${tire}Status`])} />
                                        </span>
                                    </Tooltip>
                                </Grid>
                                {tire === 'tirePressureBR' &&
                                    getInnerGrid(3, getTireColor(n[`${tire}Status`]), Math.round(n[tire]))
                                }
                            </React.Fragment>
                        ))}
                </Grid>
            </React.Fragment>
        );
    }
    return cell;
};

export const getFuelCell = (n, style) => {
    let cell = n.fuelLevelStatus || '';
    if (n.fuelLevelStatus && (n.fuelLevelStatus.toUpperCase() === 'LOW' || n.fuelLevelStatus.toUpperCase() === 'FULL')) {
        const data = {
            title: `${Math.round(n.fuelLevel)}%`,
            type: 'fuel-level',
            value: `${Math.round(n.fuelLevel)}%`,
            color: getColor(n.fuelLevelStatus),
            coustomStyle: style || {},
        };
        cell = getCommonTemp(data);
    }
    return cell;
};

export const getTemperatureCell = (temperature) => {
    let temp = '--';
    if (temperature) {
        temp = Math.round(convertTempAsPerUserSettings(temperature));
        temp = ((getUserUnit() === 'metric' && temp === 0) || (!(getUserUnit() === 'metric') && temp === 32)) ? '--' : temp; // NOSONAR
    }
    return temp;
};

export const getBatteryStatusIcon = (voltage) => {
    let color = batteryStatusConstant.other.colorValue;
    let batteryIconTooltipText = batteryStatusConstant.other.label;
    const iconWidth = { width: 20 };
    let image = <img src={getImage('batteryNotReporting')} style={iconWidth} alt={batteryStatusConstant.other.label} />;
    if (voltage == null) {
        return '';
    } else if (voltage < 11.6) {
        color = batteryStatusConstant.weak.colorValue;
        batteryIconTooltipText = batteryStatusConstant.weak.label;
        image = <img src={getImage('batteryLow')} style={iconWidth} alt={batteryStatusConstant.weak.label} />;
    } else if (voltage >= 11.6) {
        color = batteryStatusConstant.good.colorValue;
        batteryIconTooltipText = batteryStatusConstant.good.label;
        image = <img src={getImage('goodBattery')} style={iconWidth} alt={batteryStatusConstant.good.label} />;
    }

    const voltageText = voltage == null ? '-' : `${voltage}v`;
    return (
        <React.Fragment>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={batteryIconTooltipText} disableFocusListener>
                    {image}
                </Tooltip>
                <Tooltip title={voltageText} disableFocusListener>
                    <span style={{ color, marginLeft: 3 }}>{voltageText}</span>
                </Tooltip>
            </div>
        </React.Fragment>
    );
};

export const getTrackerBatteryStatus = ({
    deviceBatteryStatus,
    intVolt,
}: getTrackerBatteryStatusProps) => {
    if (!deviceBatteryStatus) return '';
    let color = batteryStatusConstant.other.colorValue;
    let deviceBatteryStatusValue = batteryStatusConstant.other.label;
    switch (deviceBatteryStatus) {
    case 'LOW_POWER':
    case 'DEPLETED': deviceBatteryStatusValue = batteryStatusConstant.weak.label;
        color = batteryStatusConstant.weak.colorValue;
        break;
    case 'CHARGED': deviceBatteryStatusValue = batteryStatusConstant.good.label;
        color = batteryStatusConstant.good.colorValue;
        break;
    default: break;
    }

    return (
        <Tooltip
            title={deviceBatteryStatusValue}
            disableFocusListener
        >
            <span style={{ color }}>{intVolt}</span>
        </Tooltip>
    );
};

export const getSignalStrengthIcon = (signalStrengthStatus) => {
    let color = '#9b9b9b';
    let image = <img src={getImage('satelliteNone')} alt={signalStrengthStatus} style={{ height: 15 }} />;
    if (signalStrengthStatus === 'Bad') {
        color = '#fc0207';
        image = <img src={getImage('satellitePoor')} alt={signalStrengthStatus} style={{ height: 15 }} />;
    } else if (signalStrengthStatus === 'Fair') {
        color = '#f5a623';
        image = <img src={getImage('satelliteFair')} alt={signalStrengthStatus} style={{ height: 15 }} />;
    } else if (signalStrengthStatus === 'Good') {
        color = '#7ed321';
        image = <img src={getImage('satelliteGood')} alt={signalStrengthStatus} style={{ height: 15 }} />;
    } else if (signalStrengthStatus === 'Excellent') {
        color = '#7ed321';
        image = <img src={getImage('satelliteExcellent')} alt={signalStrengthStatus} style={{ height: 15 }} />;
    }
    return (
        <React.Fragment>
            <Tooltip title={signalStrengthStatus} disableFocusListener>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {image}
                    <span style={{ color, paddingLeft: 5 }}>{signalStrengthStatus}</span>
                </div>
            </Tooltip>
        </React.Fragment>
    );
};

export const getFridgeStatusCell = (n) => {
    if (n.fridgeStatus) {
        const fridgeStatus = n.fridgeStatus.toUpperCase();
        if (fridgeStatus === 'ON' || fridgeStatus === 'OFF') {
            const color = getColor((fridgeStatus === 'ON') ? 'high' : 'low');
            return (
                <React.Fragment>
                    <Tooltip title={fridgeStatus || ''} disableFocusListener>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ color, paddingLeft: 5 }}>{fridgeStatus}</span>
                        </div>
                    </Tooltip>
                </React.Fragment>
            );
        }
        return n.fridgeStatus;
    }
    return '';
};
